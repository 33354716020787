<template>
	<div class="studyCourse">
		<div class="studyCoursetab clearfix">
			<div class="studyCoursetabfl fl">
        <!-- :class="item.id == qhIndex ? 'on' : ''"
					@click="qhType(item)" -->
				<span
					v-for="(item, index) in qhList"
					:key="index"

					>{{ item.name }}<em></em
				></span>
			</div>
		</div>
		<ul class="studyCourseoulqh">
			<li v-show="qhIndex == 1 ? true : false">
				<!-- <studyCourseKd v-show="studyCourseKdIshow" ref="kd" /> -->
				<div v-show="!studyCourseKdIshow">
					<studyCoursedetails
						ref="detail1"
						:KdListId="KdListId"
						:KdTitle="KdTitle"
						:studyCourseKdIshow="studyCourseKdIshow"
					/>
				</div>
			</li>
			<li v-show="qhIndex == 2 ? true : false">
				<studyCoursedetails ref="detail2" />
			</li>
			<li v-show="qhIndex == 4 ? true : false">
				<studyCoursedetails ref="detail4" />
			</li>
		</ul>
	</div>
</template>

<script>
import { checklogin } from '@/api/api';
import { setCookie } from '@/assets/js/cookie';

import studyCourseKd from './mycourse_group/studyCourseKd';
import studyCoursedetails from './mycourse_group/studyCoursedetails';
export default {
	watch: {
		studyCourseKdIshow: {
			handler: function (val) {
				if (val && this.qhIndex == 1) {
					this.$refs.kd.kdList = [];
					this.$refs.kd.getList(1);
				}
				if (!val && this.qhIndex == 1) {
					this.$refs.detail1.studyCourseList = [];
					this.$refs.detail1.qhIndex = 1;
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			qhIndex: 2,
			qhList: [],
			studyCourseKdIshow: true,
			KdListId: '',
			KdTitle: '',
		};
	},
	components: {
		studyCourseKd,
		studyCoursedetails,
	},
	mounted() {
		window.addEventListener('setItem', (e) => {
			if (e.key == 'infos') {
				if (JSON.parse(sessionStorage.getItem('infos')).identity != 3) {
					this.qhList = [
						{ name: '师资培训', id: 2 },
						// { name: "我的课单", id: 1 },
						// { name: "专属课", id: 4 },
					];
				} else {
					this.qhList = [
						{ name: '班级课程', id: 2 },
						// { name: "我的课单", id: 1 },
						// { name: "专属课", id: 4 },
					];
				}
				this.$refs.detail2.qhIndex = 2;
				this.$refs.detail2.getList(1);
				this.studyCourseKdIshow = true;
			}
		});
    if(JSON.parse(sessionStorage.getItem('infos'))){
      if (JSON.parse(sessionStorage.getItem('infos')).identity != 3) {
					this.qhList = [
						{ name: '师资培训', id: 2 },
						// { name: "我的课单", id: 1 },
						// { name: "专属课", id: 4 },
					];
				} else {
					this.qhList = [
						{ name: '班级课程', id: 2 },
						// { name: "我的课单", id: 1 },
						// { name: "专属课", id: 4 },
					];
				}
				this.$refs.detail2.qhIndex = 2;
				this.$refs.detail2.getList(1);
				this.studyCourseKdIshow = true;
    }
	},
	methods: {
		//课程类型切换
		qhType(data) {
			this.$refs.detail1.studyCourseList = [];
			this.$refs.kd.kdList = [];
			this.qhIndex = data.id;
			this.$refs.detail1.courseParams.status = 0;
			this.$refs.detail2.courseParams.status = 0;
			this.$refs.detail4.courseParams.status = 0;
			if (this.qhIndex == 1) {
				this.studyCourseKdIshow = true;
				this.$refs.kd.getList(1);
			}
			if (this.qhIndex == 2) {
				this.$refs.detail2.qhIndex = 2;
				this.$refs.detail2.getList(1);
				this.studyCourseKdIshow = true;
			}
			if (this.qhIndex == 4) {
				this.$refs.detail4.qhIndex = 4;
				this.$refs.detail4.getList(1);
				this.studyCourseKdIshow = true;
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import '../assets/css/studyCourse';
</style>
