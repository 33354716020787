<template>
  <div>
    <div class="studyCoursetabfrdiv fr">
      <span class="studyCoursetabfrdivsp" v-if="qhIndex!=4"
        ><i class="iconfont icon-study_sx"></i>筛选</span
      >
      <ul class="studyCoursetabfrdivoul">
        <li
          v-for="(item, index) in statusList"
          :key="index"
          @click="changeStatus(item)"
          :class="courseParams.status == item.id ? 'on' : ''"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="studyCoursekedetails">
      <div class="studyCoursekedetailsfh clearfix" v-if="qhIndex == 1">
        <div class="studyCoursekedetailsfhreturn" @click="returnClick()">
          返回
        </div>
        <p class="studyCoursekedetailsfhtit">
          {{ KdTitle }}
        </p>
      </div>
      <div v-if="studyCourseList.length != 0">
        <div class="studyCoursekedetailslb">
          <div
            class="studyCoursekedetailslist"
            v-for="(item, index) in studyCourseList"
            :key="index"
          >
            <div>
              <h5
                class="studyCoursekedetailslisth5"
                v-if="item.project_name"
                @click="jumpPlan(item)"
              >
                <i class="iconfont icon-study_sjhg"></i>{{ item.project_name }}
              </h5>
              <div class="studyCoursekedetailslistdiv clearfix" @click="learns(item)" v-if="item.is_expired != 1&&item.status != 1">
                <dl class="studyCoursekedetailslistdivfl fl clearfix">
                  <dt v-if="item.image"><img  :src="item.image" alt="" /></dt>
                  <dt v-else><img  :src="require('../../assets/images/nocourse.png')" alt="" /></dt>
                  <dd>
                    <h6>
                      <span v-if="item.is_top == 1">置顶</span>
                      <i v-if="item.course_type_name">{{ item.course_type_name }}</i
                      >{{ item.title }}
                    </h6>
                    <div class="studyCoursexf clearfix">
                      <p class="studyCoursexfp">{{ item.credit }}学分</p>
                      <p  v-if="item.classinfo.length == 1" class="studyCoursexfp">{{item.classinfo[0].name}}</p>
                      <div
                        class="studyCoursexfdiv"
                        v-if="item.classinfo.length>1"
                      >
                        <span
                          >共{{ item.classinfo.length }}个班级<i
                            class="iconfont icon-study_xl"
                          ></i
                        ></span>
                        <ul class="studyCoursexfdivoul">
                          <li v-for="(it, i) in item.classinfo" :key="i">
                            {{ i + 1 }}、{{ it.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="studyCoursejdt clearfix">
                      <span
                        ><em
                          :style="{ width: item.finish_rate + '%' }"
                          :class="
                            item.is_expired == 1
                              ? 'on1'
                              : item.finish_rate == 100
                              ? 'on'
                              : ''
                          "
                          >&nbsp;</em
                        ></span
                      >
                      <p>{{ item.finish_rate }}%</p>
                    </div>
                  </dd>
                </dl>
                <div class="studyCoursekedetailslistdivfr">
                  <div class="clearfix studyCoursekedetailslistdivfrd">
                    <p @click.stop="toTop(item, 0)" v-if="item.is_top == 1">
                      <i class="iconfont icon-study_qxzd"></i>取消置顶
                    </p>
                    <p @click.stop="toTop(item, 1)" v-if="item.is_top != 1&&item.is_expired == 0&&item.status == 0">
                      <i class="iconfont icon-study_zd"></i>置顶
                    </p>
                    <p @click.stop="toRemove(item)" v-if="qhIndex==1">
                      <i class="iconfont icon-study_del"></i>移出
                    </p>
                  </div>
                  <span
                    class="studyCoursekedetailslistdivfrsp"
                    >去学习</span
                  >
                </div>
              </div>
              <div class="studyCoursekedetailslistdiv clearfix" @click="learns(item)"  v-if="item.status == 1">
                <dl class="studyCoursekedetailslistdivfl fl clearfix">
                  <dt v-if="item.image"><img  :src="item.image" alt="" /></dt>
                  <dt v-else><img  :src="require('../../assets/images/nocourse.png')" alt="" /></dt>
                  <dd>
                    <h6>
                      <span v-if="item.is_top == 1">置顶</span>
                      <i v-if="item.course_type_name">{{ item.course_type_name }}</i
                      >{{ item.title }}
                    </h6>
                    <div class="studyCoursexf clearfix">
                      <p class="studyCoursexfp">{{ item.credit }}学分</p>
                      <p  v-if="item.classinfo.length == 1" class="studyCoursexfp">{{item.classinfo[0].name}}</p>
                      <div
                              class="studyCoursexfdiv"
                              v-if="item.classinfo.length>1"
                      >
                        <span
                        >共{{ item.classinfo.length }}个班级<i
                                class="iconfont icon-study_xl"
                        ></i
                        ></span>
                        <ul class="studyCoursexfdivoul">
                          <li v-for="(it, i) in item.classinfo" :key="i">
                            {{ i + 1 }}、{{ it.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="studyCoursejdt clearfix">
                      <span
                      ><em
                              :style="{ width: item.finish_rate + '%' }"
                              :class="
                            item.is_expired == 1
                              ? 'on1'
                              : item.finish_rate == 100
                              ? 'on'
                              : ''
                          "
                      >&nbsp;</em
                      ></span
                      >
                      <p>{{ item.finish_rate }}%</p>
                    </div>
                  </dd>
                </dl>
                <div class="studyCoursekedetailslistdivfr">
                  <div class="clearfix studyCoursekedetailslistdivfrd">
                    <p @click.stop="toTop(item, 0)" v-if="item.is_top == 1">
                      <i class="iconfont icon-study_qxzd"></i>取消置顶
                    </p>
                    <p @click.stop="toTop(item, 1)" v-if="item.is_top != 1&&item.is_expired == 0&&item.status == 0">
                      <i class="iconfont icon-study_zd"></i>置顶
                    </p>
                    <p @click.stop="toRemove(item)" v-if="qhIndex==1">
                      <i class="iconfont icon-study_del"></i>移出
                    </p>
                  </div>
                  <span
                          class="studyCoursekedetailslistdivfrsp grays"
                  >已取消</span
                  >
                </div>

              </div>
              <div class="studyCoursekedetailslistdiv clearfix" @click="learns(item)"  v-if="item.is_expired == 1">
                <dl class="studyCoursekedetailslistdivfl fl clearfix">
                  <dt v-if="item.image"><img  :src="item.image" alt="" /></dt>
                  <dt v-else><img  :src="require('../../assets/images/nocourse.png')" alt="" /></dt>
                  <dd>
                    <h6>
                      <span v-if="item.is_top == 1">置顶</span>
                      <i v-if="item.course_type_name">{{ item.course_type_name }}</i
                      >{{ item.title }}
                    </h6>
                    <div class="studyCoursexf clearfix">
                      <p class="studyCoursexfp">{{ item.credit }}学分</p>
                      <p  v-if="item.classinfo.length == 1" class="studyCoursexfp">{{item.classinfo[0].name}}</p>
                      <div
                              class="studyCoursexfdiv"
                              v-if="item.classinfo.length>1"
                      >
                        <span
                        >共{{ item.classinfo.length }}个班级<i
                                class="iconfont icon-study_xl"
                        ></i
                        ></span>
                        <ul class="studyCoursexfdivoul">
                          <li v-for="(it, i) in item.classinfo" :key="i">
                            {{ i + 1 }}、{{ it.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="studyCoursejdt clearfix">
                      <span
                      ><em
                              :style="{ width: item.finish_rate + '%' }"
                              :class="
                            item.is_expired == 1
                              ? 'on1'
                              : item.finish_rate == 100
                              ? 'on'
                              : ''
                          "
                      >&nbsp;</em
                      ></span
                      >
                      <p>{{ item.finish_rate }}%</p>
                    </div>
                  </dd>
                </dl>
                <div class="studyCoursekedetailslistdivfr">
                  <div class="clearfix studyCoursekedetailslistdivfrd">
                    <p @click.stop="toTop(item, 0)" v-if="item.is_top == 1">
                      <i class="iconfont icon-study_qxzd"></i>取消置顶
                    </p>
                    <p @click.stop="toTop(item, 1)" v-if="item.is_top != 1&&item.is_expired == 0&&item.status == 0">
                      <i class="iconfont icon-study_zd"></i>置顶
                    </p>
                    <p @click.stop="toRemove(item)" v-if="qhIndex==1">
                      <i class="iconfont icon-study_del"></i>移出
                    </p>
                  </div>
                </div>
                <div
                        class="studyCoursekedetailslistdivfr"

                >
                  <div class="studyCoursekedetailslistdivfrgq">
                    <img src="../../assets/images/study_yjk.png" alt="" />
                  </div>
                </div>
              </div>
              <div
                class="studyCoursekedetailslistouldiv"
                :class="item.isHeight ? 'heightAuto' : ''"
              >
                <ul class="studyCoursekedetailslistoul">
                  <li
                    class="clearfix"
                    v-for="(item1, index1) in item.solr_lesson"
                    :key="index1"
                  >
                    <span v-if="item1.livestatus == 1">正在直播</span>
                    <span v-if="item1.livestatus == 2" class="on"
                      >即将直播</span
                    >
                    <span v-if="item1.livestatus == 3" class="on1"
                      >直播预告</span
                    >
                    <em>{{ item1.media_length | formatimes("hh:mm") }}</em>
                    <img src="@/assets/images/living_blue.gif" alt="" />
                    <p>{{ item1.lesson_title }}</p>
                    <div v-if="item1.livestatus == 1">
                      进入直播<i class="iconfont icon-study_xz"></i>
                    </div>
                    <div
                      v-if="item1.livestatus == 2 || item1.livestatus == 3"
                      class="on"
                    >
                      直播未开始<i class="iconfont icon-study_xz"></i>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="studyCoursekedetailslistzk"
                @click="changeShow(item)"
                v-if="!item.isShow"
              >
                {{ !item.isHeight ? "展开" : "收起" }}
                <i
                  class="iconfont"
                  :class="
                    !item.isHeight
                      ? 'icon-xialajiantouxiao'
                      : 'icon-shouqijiantouxiao'
                  "
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="studyCoursekdpage" style="margin-top: 30px">
          <p class="moreBtn" @click="getMore">
            <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
          </p>
        </div>
        <div class="isDel">
          <el-dialog
            :visible.sync="removeDia"
            width="400px"
            center
            :show-close="false"
          >
            <h6 class="isDelh6">确认将该课程移出课单吗？</h6>
            <span slot="footer" class="dialog-footer">
              <el-button @click="removeDia = false">取 消</el-button>
              <el-button type="primary" @click="removeSubmit">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
      <div v-else>
        <div class="studyCoursekedetailswkc" v-if="qhIndex==4">
          <img src="../../assets/images/study_zw.png" alt="" />
          <p>暂未安排相关课程，去看看其他课程吧~</p>
          <span @click="$router.push('/search')">去看看</span>
        </div>
        <div  v-else  class="studyCoursekedetailswkc">
          <img src="../../assets/images/study_zw.png" alt="" />
          <p>暂无相关课程，快去添加感兴趣的课程吧~</p>
          <span @click="$router.push('/search')">去看看</span>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {
  myCourseKDDetail,
  myCourseKDRemove,
  myCourseKDTop,
} from "../../assets/api";
export default {
  props: ["KdListId", "KdTitle", "studyCourseKdIshow"],
  name: "studyCoursedetails",
  watch: {
    studyCourseKdIshow: {
      handler: function (val) {
        if (!val) {
          this.getList(1);
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      currentPage41: 4,
      removeDia: false,
      removeId: "",
      studyCourseList: [],
      statusList: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "学习中",
        },
        {
          id: 2,
          name: "已过期",
        },
      ],
      qhIndex: "",
      courseParams: {
        list_id: "",
        list_type: 0,
        status: 0,
        page: 1,
      },
      btnMessage: "加载更多",
    };
  },
  mounted() {},
  methods: {
    learns(data) {
      if(data.is_expired != 1&&data.status != 1){
        this.$router.push({
          path: "/newlearn",
          query: {
            course_id: data.id,
            cst_id:data.classinfo[0].id,
          },
        });
      }else{
        this.$router.push({
          path: "/learn",
          query: {
            course_id: data.id,
            cst_id:data.classinfo[0].id,
          },
        });
      }

    },
    changeStatus(item) {
      this.courseParams.status = item.id;
      this.getList(1);
    },
    getList(type) {
      if (type == 1) {
        this.courseParams.page = 1;
        this.studyCourseList = [];
      }
      const params = {
        list_id: this.qhIndex != 1 ? this.qhIndex : this.KdListId,
        list_type: this.qhIndex,
        status: this.courseParams.status,
        page: this.courseParams.page,
      };
      myCourseKDDetail(params).then((res) => {
        if (res.code == 0) {
          if (res.data.list.length > 0) {
            if (type == 1) {
              this.studyCourseList = res.data.list;
            }
            if (type == 2) {
              this.studyCourseList = this.studyCourseList.concat(res.data.list);
            }
            this.$nextTick(() => {
              this.studyCourseList.forEach((item, index) => {
                if (
                  document.getElementsByClassName(
                    "studyCoursekedetailslistouldiv"
                  )[index].offsetHeight >=
                  document.getElementsByClassName(
                    "studyCoursekedetailslistouldiv"
                  )[index].childNodes[0].offsetHeight
                ) {
                  this.$set(item, "isShow", true);
                } else {
                  this.$set(item, "isShow", false);
                }
                this.$set(item, "isHeight", false);
              });
            });
          } else {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.courseParams.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.courseParams.page++;
        this.getList(2);
      }
    },
    changeShow(item) {
      item.isHeight = !item.isHeight;
    },
    //返回课单
    returnClick() {
      this.$parent.studyCourseKdIshow = true;
      this.courseParams.status = 0;
    },
    //置顶
    toTop(item, status) {
      const params = {
        id: item.list_course_id,
        status: status,
      };
      myCourseKDTop(params).then((res) => {
        if (res.code == 0) {
          this.getList(1);
        }
      });
    },
    //移出
    toRemove(item) {
      this.removeDia = true;
      this.removeId = item.list_course_id;
    },
    //移出确定
    removeSubmit() {
      const params = {
        id: this.removeId,
      };
      myCourseKDRemove(params).then((res) => {
        if (res.code == 0) {
          this.removeDia = false;
          this.getList(1);
        }
      });
    },
    jumpPlan(item) {
      this.$router.push({
        path: "/plan_detail",
        query: {
          project_id: item.project_id,
          fromType:3
        },
        meta: {
          type: 2,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/studyCourse";
.moreBtn {
  margin: 0 auto;
  margin-top: 30px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #666;
  &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
  i {
    font-size: 14px;
  }
}
</style>
