<template>
  <div>
    <span class="studyCoursetabfr fr" @click="addKD"
      ><i class="iconfont icon-study_button"></i>创建新课单</span
    >
    <div class="studyCoursekediv">
      <ul class="studyCoursekd clearfix">
        <li
          v-for="(item, index) in kdList"
          :key="index"
          :class="(index + 1) % 4 == 0 ? 'on' : ''"
        >
          <div class="studyCoursekdtit clearfix">
            <div class="studyCoursekdtitfl">
              <span
                ><i class="iconfont icon-mrcd mr5" v-if="index == 0"></i
                >{{ item.name }}</span
              >
            </div>
            <div class="studyCoursekdtitfr" v-if="index != 0">
              <span
                class="iconfont icon-study_shanchu studyCoursekdtitfrsp"
              ></span>
              <div class="studyCoursekdtitfrdiv">
                <span @click="editKD(item)"
                  ><i class="iconfont icon-study_bj1"></i>编辑</span
                >
                <span @click="delKD(item)"
                  ><i class="iconfont icon-study_del"></i>删除</span
                >
              </div>
            </div>
          </div>
          <div class="studyCoursekdimg" @click="studyCoursekdList(item)">
            <div
              class="studyCoursekdimg1"
              :class="index == 0 && item.covers_pic[0] ? 'on' : ''"
            >
              <i v-if="index == 0"></i>
              <img
                :src="item.covers_pic[0]"
                alt=""
                v-if="item.covers_pic[0] && index != 0"
              />
              <img
                src="../../assets/images/study_mrkd.png"
                alt=""
                v-if="!item.covers_pic[0]"
              />
            </div>
            <div
              class="studyCoursekdimg2"
              :class="index == 0 && item.covers_pic[1] ? 'on' : ''"
            >
              <img
                :src="item.covers_pic[1]"
                alt=""
                v-if="item.covers_pic[1] && index != 0"
              />
              <img
                src="../../assets/images/study_mrkd.png"
                alt=""
                v-if="!item.covers_pic[1]"
              />
            </div>
            <div
              class="studyCoursekdimg3"
              :class="index == 0 && item.covers_pic[2] ? 'on' : ''"
            >
              <img
                :src="item.covers_pic[2]"
                alt=""
                v-if="item.covers_pic[2] && index != 0"
              />
              <img
                src="../../assets/images/study_mrkd.png"
                alt=""
                v-if="!item.covers_pic[2]"
              />
            </div>
          </div>
        </li>
      </ul>
      <div class="addKd">
        <el-dialog
          :title="AddEditType == 1 ? '创建课单' : '编辑课单'"
          :visible.sync="AddEditDia"
          width="600px"
        >
          <el-form :model="form">
            <el-form-item label="">
              <el-input
                v-model="form.name"
                autocomplete="off"
                placeholder="请输入课单名称，最多10个字符"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="AddEditSubmit">确 定</el-button>
          </div>
        </el-dialog>
      </div>
      <div class="isDel">
        <el-dialog
          :visible.sync="DelDia"
          width="400px"
          center
          :show-close="false"
        >
          <h6 class="isDelh6">确认删除课单吗？</h6>
          <p class="isDelp">删除后，课单内的课程也一并被删除</p>
          <span slot="footer" class="dialog-footer">
            <el-button @click="DelDia = false">取 消</el-button>
            <el-button type="primary" @click="DelSubmit">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <p class="moreBtn" @click="getMore">
        <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  mycourseKDList,
  myCourseKDAdd,
  myCourseKDEdit,
  myCourseKDDel,
} from "../../assets/api";
import {getCookie} from "../../../../../assets/js/cookie";
export default {
  name: "studyCourseKd",
  data() {
    return {
      total: "",
      page: 1,
      AddEditDia: false,
      DelDia: false,
      form: {
        name: "",
      },
      kdList: [],
      AddEditType: "",
      EditId: "",
      DelId: "",
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    //查看课单
    studyCoursekdList(item) {
      this.$parent.studyCourseKdIshow = false;
      this.$parent.KdListId = item.id;
      this.$parent.KdTitle = item.name;
    },
    getList(type) {
      const params = {
        page: this.page,
        school_id: getCookie('siteid'),
      };
      mycourseKDList(params).then((res) => {
        if (res.code == 0) {
          if (res.data.list != null) {
            if (type == 1) {
              this.kdList = res.data.list;
            }
            if (type == 2) {
              this.kdList = this.kdList.concat(res.data.list);
            }
          } else {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList(2);
      }
    },
    //新增
    addKD() {
      this.AddEditType = 1;
      this.form.name = "";
      this.AddEditDia = true;
    },
    //编辑
    editKD(item) {
      this.EditId = item.id;
      this.AddEditType = 2;
      this.form.name = item.name;
      this.AddEditDia = true;
    },
    delKD(item) {
      this.DelId = item.id;
      this.DelDia = true;
    },
    //新增编辑确定
    AddEditSubmit() {
      if (this.AddEditType == 1) {
        const params = {
          school_id: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
          name: this.form.name,
        };
        myCourseKDAdd(params).then((res) => {
          if (res.code == 0) {
            this.$message.success("新增课单成功");
            this.getList(1);
            this.AddEditDia = false;
          }
        });
      }
      if (this.AddEditType == 2) {
        const params = {
          id: this.EditId,
          name: this.form.name,
        };
        myCourseKDEdit(params).then((res) => {
          if (res.code == 0) {
            this.$message.success("编辑课单成功");
            this.getList(1);
            this.AddEditDia = false;
          }
        });
      }
    },
    //删除
    DelSubmit() {
      const params = {
        id: this.DelId,
      };
      myCourseKDDel(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除课单成功");
          this.getList(1);
          this.DelDia = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/studyCourse";
.studyCoursekdimg {
  .on {
    i {
      background-image: url("../../assets/images/mrcdBg.png");
    }
  }
}
.moreBtn {
  margin: 0 auto;
  margin-top: 30px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #666;
  &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
  i {
    font-size: 14px;
  }
}
</style>
